<script setup>
import {computed, toRef, useAttrs} from 'vue';


const props = defineProps({
    type: {
        type: String,
        default: 'submit',
    },
    color: {
        type: String,
        default: 'cali-green',
    },
    textColor: {
        type: String,
        default: 'black',
    },
});

const attrs = useAttrs();
const isDisabled = toRef(attrs, 'disabled');

const buttonClasses = computed(() => {
    let bgColor = 'bg-' + props.color;
    let hoverColor = 'hover:opacity-70';
    let textColor = 'text-' + props.textColor;

    let classes =  [
        "flex", "justify-center", "items-center", "flex-shrink-0",
        "h-10", "custom-width",
        "transition", "ease-in-out", "duration-150",
        "font-bold",
        "text-lg",
    ];
    if (isDisabled.value) {
        // Apply disabled-specific classes
        classes.push('text-cali-super-light-gray', 'bg-cali-primary-contrast', 'cursor-not-allowed');
    } else {
        // Apply normal state classes
        classes.push(bgColor, hoverColor, textColor);
    }
    return classes;
});
</script>

<template>
    <button :type="type" :class="buttonClasses" >
        <slot />
    </button>
</template>
